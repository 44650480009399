import React from 'react'

const InsideHamBtn = () => {
  return (
    <div className='flex flex-col absolute top-20 right-0 bg-opacity-55 bg-black rounded-md'>
        <button className='p-2 hover:bg-white hover:bg-opacity-40 hover:text-white rounded-md'>Inside Ham Button</button>
        <button className='p-2 hover:bg-white hover:bg-opacity-40 hover:text-white rounded-md'>Inside Ham Button</button>
        <button className='p-2 hover:bg-white hover:bg-opacity-40 hover:text-white rounded-md'>Inside Ham Button</button>
        <button className='p-2 hover:bg-white hover:bg-opacity-40 hover:text-white rounded-md'>Inside Ham Button</button>
        <button className='p-2 hover:bg-white hover:bg-opacity-40 hover:text-white rounded-md'>Inside Ham Button</button>
    </div>
  )
}

export default InsideHamBtn