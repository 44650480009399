import React from 'react'

const Greetings = () => {
  return (
    <div className='flex justify-center flex-col py-5 h-screen z-20'>
        <h1 className="font-bold text-2xl md:text-4xl">Farros Haydar Rayhan</h1>
        <h2 className='px-5'>Full Stack Software Engineer</h2>
    </div>
  )
}

export default Greetings